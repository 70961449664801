import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import tw from 'twin.macro'

import { HeaderStyle } from './UI'

const Partners = ({ partners }) => {
  return (
    <>
      {partners && (
        <section tw="bg-[#E9F1F3]">
          <div tw="mx-7 py-14 lg:(max-w-screen-lg mx-auto py-20)">
            {/* Headline */}
            <h2 css={[HeaderStyle, tw`mb-7 lg:(text-4xl)`]}>Our Partners</h2>
            {/* Logos */}
            <div tw="flex flex-wrap justify-around sm:justify-between items-center">
              {partners.map((partner, index) => (
                <div
                  tw="px-4 my-2 w-1/2 lg:(flex-1 my-0)"
                  key={`partner-${index}`}
                >
                  <GatsbyImage
                    tw="mb-5 flex-shrink"
                    image={partner.logo.gatsbyImageData}
                    alt={partner.name}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default Partners
